import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

const Viewer = ({ url, v2 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const compareId = searchParams.get("compare");
  const lookupID = searchParams.get("lookupID");
  const params = window.location.pathname.split("/");
  const [image360Props, setImage360Props] = useState({
    loaded: false,
  });
  // const url = "https://safecam360.s20.ai/api";
  // let url = "";
  // if (params.includes("c24-staging")) url = "https://cars24.s20.ai/api";
  // else if (params.includes("c24-capture-v2"))
  //   url = "https://safecam360.s20.ai/api";
  // else url = "https://safecam360.s20.ai/api";
  //   const url = "http://localhost:5002/api";
  const user = {
    email: "testqc@s20.ai",
    password: "qwerty",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tokenResponse = await fetch(`${url}/auth`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        const { token } = await tokenResponse.json();
        const propResponse = await fetch(
          v2
            ? `${url}/capture/prop-data-v2/${lookupID}`
            : `${url}/capture/prop-data/${lookupID}`,
          {
            method: "GET",
            headers: {
              "x-auth-token": token,
            },
          }
        );
        const _image360Props = await propResponse.json();
        if (compareId) {
          const propResponseCompareTo = await fetch(
            params.includes("c24-capture-v2")
              ? `${url}/capture/prop-data-v2/${compareId}`
              : `${url}/capture/prop-data/${compareId}`,
            {
              method: "GET",
              headers: {
                "x-auth-token": token,
              },
            }
          );
          const _image360PropsCompareTo = await propResponseCompareTo.json();
          _image360Props.exterior360 = [
            ..._image360Props.exterior360,
            ..._image360PropsCompareTo.exterior360,
          ];
          _image360Props.interior360 = [
            ..._image360Props.interior360,
            ..._image360PropsCompareTo.interior360,
          ];
        }
        console.log(_image360Props);
        setImage360Props({ loaded: true, data: _image360Props });
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="app">
      <Helmet>
        {window.innerWidth < 1100 ? (
          <script src="https://cars24.s20.ai/safecam360/safecam360-viewer-web_sdk-0.1.0"></script>
        ) : (
          <script src="https://cars24.s20.ai/safecam360/safecam360-viewer-web_sdk-0.1.1"></script>
          // <script src="https://staging.s20.ai/safecam360/safecam360-viewer-web_sdk-0.1.1"></script>
          // <script src="http://localhost:5001/Safecam360Viewer.js"></script>
          // <script src="http://localhost:5001/direflowBundle.js"></script>
        )}
      </Helmet>
      {image360Props.loaded ? (
        <div className="app-container">
          <safecam-360-viewer
            interior360={JSON.stringify(image360Props.data.interior360)}
            exterior360={JSON.stringify(image360Props.data.exterior360)}
            galleryData={JSON.stringify(image360Props.data.galleryData)}
            otherConfig={JSON.stringify({
              enableGallery: true,
              hideGalleryButton: false,
            })}
            analytics={JSON.stringify({
              providerDetails: { name: "GTM", publicID: "GTM-T22M6KF" },
              userDetails: {
                id: "GUEST",
                email: user.email || "GUEST",
              },
              productID: lookupID,
            })}
            toggleFullscreen={true}
            enableHotspots={true}
          ></safecam-360-viewer>
        </div>
      ) : (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Viewer;
