import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Helmet } from "react-helmet";
import Viewer from "./Components/Viewer";
import ProductList from "./Components/ProductList";

function App() {
  const isLocal = false;
  return (
    <BrowserRouter basename="demo">
      <Routes>
        <Route
          path="s20-staging"
          element={
            <Viewer
              url={
                isLocal
                  ? "http://localhost:5002/api"
                  : "https://safecam360.s20.ai/api"
              }
            />
          }
        />
        <Route
          path="c24-staging"
          element={
            <Viewer
              url={
                isLocal
                  ? "http://localhost:5002/api"
                  : "https://cars24.s20.ai/api"
              }
            />
          }
        />
        <Route
          path="s20-capture-v2"
          element={
            <Viewer
              url={
                isLocal
                  ? "http://localhost:5002/api"
                  : "https://safecam360.s20.ai/api"
              }
              v2
            />
          }
        />
        <Route
          path="list"
          element={
            <ProductList
              url={
                isLocal
                  ? "http://localhost:5002/api"
                  : "https://safecam360.s20.ai/api"
              }
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
