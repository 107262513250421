import constants from "./constants";

export const chipTheme = {
  styleOverrides: {
    root: {
      fontSize: 12,
      padding: "2px 6px",
    },
  },
  variants: [
    {
      props: {
        label: constants.REJECTED,
      },
      style: {
        color: "#B32624",
        background: "#FDCED3",
      },
    },
    {
      props: {
        label: constants.COMPLETE,
      },
      style: {
        color: "#006200",
        background: "#C4E8C1",
      },
    },
    {
      props: {
        label: constants.IN_PROGRESS,
      },
      style: {
        color: "#EF6E0B",
        background: "#FEF4E9",
      },
    },
    {
      props: {
        label: constants.PARTIAL,
      },
      style: {
        color: "#0071BC",
        background: "#E6F1F8",
      },
    },
    {
      props: {
        label: constants.NOT_STARTED,
      },
      style: {
        background: "#C8C8C8",
      },
    },
  ],
};
export const productMenuStyles = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    width: "200px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
