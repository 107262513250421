export const getDateString = (date = new Date()) => {
  const month = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return `${day[date.getDay()]}, ${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()} - ${getTime(date)}`;
};

const getTime = (date = new Date()) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const extractNameInitials = (name) => {
  let initials = "";
  name.split(" ").forEach((word) => {
    initials += word.charAt(0).toUpperCase();
  });

  return initials;
};

export const hideOtherHotspots = (currentHotpost, allHotspots) => {
  allHotspots
    .filter((d) => d.id !== currentHotpost.id)
    .forEach((hotspot) => {
      console.log(hotspot.id);
      let el = document.getElementById(hotspot.id);
      if (el) el.style.display = "none";
    });
};

export const sanitizeHotspots = (hotspotArr) =>
  hotspotArr.map((hotspot) => {
    let copy = JSON.parse(JSON.stringify(hotspot));
    delete copy.preview;
    return copy;
  });

export const packager = (config) => {
  console.log("Config");
  console.log(config);
  const { components, stylesTypeDefinitions } = config;

  const componentPackages = components.map((component) => {
    console.log("Component");
    console.log(component.name);
    const componentPackage = {
      name: component.name,
      jsx: component,
      props: { ...config[component.name].defaultProps },
      styleSettings: config[component.name].editableStyles.map((style) => {
        return {
          name: style,
          type: stylesTypeDefinitions[style],
        };
      }),
      customStyles: {},
    };
    return componentPackage;
  });

  return componentPackages;
};
