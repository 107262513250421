import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Chip,
  createTheme,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { chipTheme } from "./themeUtils";
import constants from "./constants";

const theme = createTheme({
  components: {
    MuiStack: {
      variants: [
        {
          props: {
            id: "filter-bar",
          },
          style: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            background: "white",
            padding: "0.5em",
            margin: "1.5em 0 0 0 !important",
            borderRadius: "8px",
            border: "1px solid #C8C8C8",
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "white",
        },
      },
    },
    MuiFormGroup: {
      variants: [
        {
          props: {
            id: "radio-buttons-group",
          },
          style: {
            flexDirection: "row",
            gap: "0.5em",
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          color: "#5B5B5B",
          fontFamily: "Poppins",
        },
      },
    },
  },
});
const ToolBarSecondary = ({ filterByPID }) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack
        sx={{
          margin: "1em 0 1em 0",
          px: "10%",
        }}
        spacing={4}
        width={"100%"}
      >
        <Stack width={"80%"}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: <SearchOutlined />,
            }}
            id="outlined-required"
            label="Search by Appointment ID"
            onChange={(e) => filterByPID(e.target.value)}
          />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
};

export default ToolBarSecondary;
