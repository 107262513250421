import {
  Chip,
  CircularProgress,
  createTheme,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  ThemeProvider,
  ToggleButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Link } from "react-router-dom";
import { chipTheme, productMenuStyles } from "./utils/themeUtils";
import { Edit, OpenInNew, Report } from "@mui/icons-material";
import constants from "./utils/constants";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: "fit-content",
          borderRadius: 10,
          cursor: "pointer",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "0 0 1em 0",
        },
        title: {
          fontSize: 22,
          paddingBottom: "0.25em",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: "card-text",
          },
          style: {
            fontSize: 12,
          },
        },
      ],
    },
    MuiChip: {
      ...chipTheme,
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          position: "relative",
          // borderTop: "1px solid #EDEDED",
          // borderBottom: "1px solid #EDEDED",
          margin: "0 1em 0 1em",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "0.75em",
          color: "#5B5B5B",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "1em",
          gap: "1em",
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          width: "345px",
          height: "194px",
        },
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: {
            value: "preview",
          },
          style: {
            background: "white",
            color: "#EF6E0B !important",
            "&.Mui-disabled": {
              pointerEvents: "auto !important",
              cursor: "not-allowed",
              color: "#C8C8C8 !important",
              "&:hover": {
                background: "white",
              },
            },
          },
        },
        {
          props: {
            value: "sdk-360",
          },
          style: {
            color: "white !important",
            background: "#EF6E0B ",
            "&:hover": {
              color: "#EF6E0B !important",
            },
            "&.Mui-disabled": {
              pointerEvents: "auto !important",
              cursor: "not-allowed",
              background: "#C8C8C8 !important",
              "&:hover": {
                color: "white !important",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: "2px 24px",
          borderRadius: 8,
          fontWeight: "600",
          border: "2px solid #EF6E0B",
          fontSize: "13px",
          letterSpacing: "0.04em",
        },
        primary: {
          background: "#EF6E0B",
          color: "white !important",
        },
        secondary: {},
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: {
            className: "menu-link",
          },
          style: {
            fontSize: "13px",
          },
        },
      ],
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "fit-content !important",
          marginRight: "0.75em",
        },
      },
    },
  },
});

const ProductListComponent = ({
  productId,
  status,
  dateOfUpload,
  lastUpdated,
  onStart,
  imageSrc,
  setStatusToInProgress,
  setStatusToRejected,
  openModalWithJSX, //! this is a custom prop injected via redux (not to be passed from parent)
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setIsLoading(true);
    function onImagesLoaded(event) {
      let image = document.getElementById("product-img" + productId);
      image.addEventListener("load", function () {
        event();
      });
    }

    onImagesLoaded(function () {
      setIsLoading(false);
    });
  }, [productId]);

  return (
    <ThemeProvider theme={theme}>
      <Link
        to={`/s20-staging?lookupID=${productId}`}
        style={{ textDecorationLine: "none" }}
      >
        <Card onClick={(e) => {}}>
          <CardHeader
            title={
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width={"100%"}
                position={"relative"}
              >
                <img
                  id={"product-img" + productId}
                  style={{
                    objectFit: "cover",
                    maxHeight: "194px",
                    width: "100%",
                    height: "100%",
                    borderRadius: "5px 5px 0 0",
                  }}
                  alt={productId}
                  src={
                    imageSrc ||
                    "https://media.wired.com/photos/5b899992404e112d2df1e94e/master/pass/trash2-01.jpg"
                  }
                />
                {isLoading && (
                  <div
                    id="image-loader"
                    style={{
                      position: "absolute",
                      height: "100%",
                      maxHeight: "194px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "5px",
                      alignItems: "center",
                      top: "0em",
                      backdropFilter: "blur(10px)",
                    }}
                  >
                    <CircularProgress size={20} />
                  </div>
                )}
                {/* <IconButton
                aria-label="settings"
                id="basic-button"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton> 
               <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={productMenuStyles}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="menu-link"
                  target="_blank"
                  disabled={status !== constants.COMPLETE}
                >
                  <ListItemIcon>
                    <OpenInNew fontSize="small" />
                  </ListItemIcon>
                  <Link
                    target="_blank"
                    to={{ pathname: `https://cars24.s20.ai/demo/${productId}` }}
                  >
                    Preview
                  </Link>
                </MenuItem>
                <MenuItem
                  component={Link}
                  className="menu-link"
                  disabled={status === constants.IN_PROGRESS}
                  // target="_blank"
                  to={`/dashboard/product-list/sdk/${productId}?editorOpen=true`}
                  onClick={(_) => {
                    setStatusToInProgress(productId);
                  }}
                >
                  <ListItemIcon>
                    <Edit fontSize="small" />
                  </ListItemIcon>
                  Edit Hotspots
                </MenuItem>
                <MenuItem
                  className="menu-link"
                  onClick={(_) => {
                    onStart(productId);
                    setStatusToRejected(productId);
                  }}
                >
                  <ListItemIcon>
                    <Report fontSize="small" />
                  </ListItemIcon>
                  Reject
                </MenuItem>
              </Menu> */}
              </Stack>
            }
            // subheader={

            // }
          />

          <CardContent>
            {/* <Stack>{productId}</Stack> */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width={"100%"}
            >
              <Stack>
                <Typography variant="card-text">
                  App ID <b style={{ color: "black" }}>{productId}</b>
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* <Typography
                    sx={{
                      paddingRight: "1em",
                    }}
                    variant="card-text"
                  >
                    QC Status
                  </Typography> */}
                <Chip size="small" label={status} />
              </Stack>
            </Stack>

            <Stack>
              <Typography variant="card-text">
                Captured: <b>{dateOfUpload}</b>
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="card-text">
                Last Edited: <b>{lastUpdated}</b>
              </Typography>
            </Stack>

            {/* <CardMedia
            component="img"
            height="194"
            image={
              imageSrc ||
              "https://media.wired.com/photos/5b899992404e112d2df1e94e/master/pass/trash2-01.jpg"
            }
            alt={productId}
          /> */}
          </CardContent>
          {/* <CardActions>
          <ToggleButton
            color="secondary"
            value="preview"
            target="_blank"
            disabled={status !== constants.COMPLETE}
            href={
              status !== constants.COMPLETE
                ? ""
                : `https://cars24.s20.ai/demo/${productId}`
            }
          >
            preview
          </ToggleButton>
          <ToggleButton
            color="primary"
            value="sdk-360"
            // target="_blank"
            disabled={status === constants.IN_PROGRESS}
            href={
              status === constants.IN_PROGRESS
                ? ""
                : `/dashboard/product-list/sdk/${productId}?editorOpen=true`
            }
            onClick={(_) => {
              setStatusToInProgress(productId);
              onStart(productId);
            }}
          >
            {getEditButtonText(status)}
          </ToggleButton>
        </CardActions> */}
        </Card>
      </Link>
    </ThemeProvider>
  );
};

const getEditButtonText = (status) => {
  switch (status) {
    case constants.PARTIAL:
      return "Continue";
    case constants.COMPLETE:
      return "Edit";
    default:
      return "Start";
  }
};

export default ProductListComponent;
