import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Alert,
  Avatar,
  CircularProgress,
  createTheme,
  Grid,
  Pagination,
  Snackbar,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import history from "./utils/history";
import { KeyboardArrowDown, KeyboardArrowUp, Title } from "@mui/icons-material";
import ProductListComponent from "./ProductListCard";
import ToolBarSecondary from "./utils/ToolBarSecondary";
import { getDateString } from "./utils/generalUtils";
import axios from "axios";

const theme = createTheme({
  components: {
    MuiStack: {
      variants: [
        {
          props: {
            id: "sort-stack",
          },
          style: {
            paddingBottom: "1em",
            paddingLeft: "11em",
            paddingRight: "11em",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            textAlign: "end",
            gap: "0.5em",
          },
        },
        {
          props: { className: "sort-options" },
          style: {
            zIndex: "10",
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.25em",
          },
        },
        {
          props: { id: "active" },
          style: {
            color: "#0071BC",
          },
        },
      ],
    },
  },
});

const ProductList = ({ url }) => {
  //   const ProductList = ({
  //     setStatusFilter,
  //     productList,
  //     getInitialProductPageData,
  //     getCurrentPageProducts,
  //     toggleModal,
  //     setCurrentPage,
  //     userId,
  //     organisationId,
  //     openModalWithJSX,
  //     getSDKViewerProductData,
  //     currentAppointmentId,
  //     statusFilter,
  //   }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get("org");
  const user = {
    email: "testqc@s20.ai",
    password: "qwerty",
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(20);
  const [selectedTotal, setSelectedTotal] = useState(9);
  const [orgDetails, setOrgDetails] = useState(null);
  const [products, setProducts] = useState([]);
  const [isProductListLoading, setIsProductListLoading] = useState(true);
  const [sort, setSort] = useState({
    createdAt: -1,
  });
  const [filter, setFilter] = useState({
    filterByPIDvalue: "",
    filterByDraftVal: true,
    filterByPublishedVal: true,
    filterByRejectedVal: true,
    filterByHotspots: false,
  });
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(null);

  const exceptionHandlerForInvalidProducts = (product, keys = []) => {
    try {
      switch (keys.length) {
        case 1:
          return product[keys[0]];
        default:
          return product[keys[0]][keys[1]];
      }
    } catch (e) {
      return "unset";
    }
  };

  const setNewPage = (pageNo, isNewPage = false) => {
    if ((pageNo !== currentPage && pageNo > 0 && pageNo <= totalPages) || isNewPage) {
      history.push({
        search: `?page=${pageNo}${orgId ? `&org=${orgId}` : ""}`,
      });
      setCurrentPage(pageNo);
      fetchData(pageNo);
      //   getCurrentPageProducts(
      //     pageNo,
      //     filter.filterByPIDvalue,
      //     statusFilter,
      //     filter.filterByHotspots,
      //     sort
      //   );
    }
  };

  const fetchData = async (pageNo, filterByPIDvalue) => {
    setIsProductListLoading(true);
    try {
      const tokenResponse = await fetch(`${url}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const { token } = await tokenResponse.json();

      const response = await axios
        .get(`${url}/capture/fetch-all`, {
          headers: {
            "x-auth-token": token,
          },
          params: {
            page: pageNo || currentPage,
            organisationId: orgId,
            filterForStatus: null,
            filterByHotspots: null,
            filterByProductId: filterByPIDvalue
              ? filterByPIDvalue
              : filter.filterByPIDvalue.length === 0
              ? null
              : filter.filterByPIDvalue,
            sort: { createdAt: -1 },
          },
        })
        .catch((err) => {
          return err.response;
        });
      setProducts(response.data.data.captureData);
      setTotalPages(response.data.data.totalPages);
      //response.data.data.parentTotal;
      setSelectedTotal(response.data.data.selectedTotal);
    } catch (error) {
      console.log("error", error);
    }
    setIsProductListLoading(false);
  };

  const fetchOrgData = async () => {
    try {
      const tokenResponse = await fetch(`${url}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const { token } = await tokenResponse.json();

      const response = await axios
        .get(`${url}/auth/get-org/${orgId}`, {
          headers: {
            "x-auth-token": token,
          },
        })
        .catch((err) => {
          return err.response;
        });
      setOrgDetails(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setOrgDetails(null);
    fetchOrgData();
    fetchData();
  }, []);

  useEffect(() => {
    // getInitialProductPageData();
    let pageNo;
    pageNo = parseInt(history.location.search.split("?page=")[1]);
    if (!isNaN(pageNo)) {
      setNewPage(pageNo, true);
    } else {
      setNewPage(currentPage, true);
    }
  }, []);

  useEffect(() => {
    setNewPage(currentPage, true);
  }, [sort]);

  const renderProductListComponents = (products = []) => {
    if (!isProductListLoading) {
      return products.map((product) => {
        return (
          <Grid item key={product._id}>
            <ProductListComponent
              onStart={(e) => setNewPage(currentPage, true)}
              hotspotInfo={product.capture_data}
              status={product.status}
              productId={exceptionHandlerForInvalidProducts(product, ["product_id"])}
              createdBy={exceptionHandlerForInvalidProducts(product, ["created_by", "name"])}
              dateOfUpload={getDateString(new Date(product.createdAt))}
              lastUpdated={getDateString(new Date(product.updatedAt))}
              location={exceptionHandlerForInvalidProducts(product, ["organisation_id", "name"])}
              imageSrc={product.thumbnail}
            />
          </Grid>
        );
      });
    } else {
      return (
        <Grid
          sx={{
            height: "10%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: " rgba(143, 143, 143, 1)" }} />
        </Grid>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        sx={{
          height: "100%",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        {orgDetails && (
          <Grid
            sx={{
              margin: "1.5em 0 1.5em 0",
            }}
            container
            alignItems={"center"}
            flexDirection={"column"}
          >
            <Grid
              container
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap="1em"
            >
              <Typography variant="h5">{orgDetails.name?.toUpperCase()}</Typography>
              <Avatar src={orgDetails.logo} />
            </Grid>
            <Typography variant="h6">{orgDetails.address || orgDetails.location}</Typography>
          </Grid>
        )}
        <ToolBarSecondary
          currentPage={currentPage}
          totalPages={totalPages}
          paginationBackFnc={() => {
            setNewPage(currentPage - 1);
          }}
          paginationForwardFnc={() => {
            setNewPage(currentPage + 1);
          }}
          filterByPID={(e) => {
            fetchData(currentPage, e);
            // setFilter({ ...filter, filterByPIDvalue: e });
          }}
        />
        <Stack id="sort-stack">
          <Stack flexDirection={"row"}>
            {selectedTotal} cars - Page {currentPage} of {totalPages}
          </Stack>
        </Stack>
        <Grid container justifyContent={"center"} spacing={4} columns={3}>
          {renderProductListComponents(products)}
        </Grid>
        <Pagination
          sx={{
            display: "flex",
            justifyContent: "center",
            my: "1em",
          }}
          page={currentPage}
          count={totalPages}
          shape="rounded"
          onChange={(e, page) => setNewPage(page)}
        />
      </Grid>
    </ThemeProvider>
  );
};

//   return <div>ProductList</div>;
// };

export default ProductList;
