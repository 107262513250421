let constants = {
  //envs
  C24_PROD: "c24prod",
  S20_PROD: "s20prod",
  LOCAL: "local",

  SOP: [
    { id: "hotspots", label: "Hotspots" },
    { id: "live-view", label: "Live View" },
    { id: "slots", label: "Slots" },
  ],

  UPDATE_STATUS: {
    NONE: "NONE",
    TO_BE_UPDATED: "TO_BE_UPDATED",
    UPDATING: "UPDATING",
    UPDATED: "UPDATED",
    ERROR: "ERROR",
  },

  DRAFT: "Draft",
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  PARTIAL: "Partial",
  COMPLETE: "Complete",
  REJECTED: "Rejected",
  SHOW_CARS_WITH_HOTSPOTS: "Only Cars With Hotspots",
  INTERIOR: "interior",
  EXTERIOR: "exterior",
};
export default Object.freeze(constants);
